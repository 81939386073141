import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from "./components/Home";
import "bootstrap-icons/font/bootstrap-icons.css";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Admin from './pages/Admin';
import Login from './pages/Login';
import ErrorPage from './components/ErrorPage';
import PDFViewer from './pages/PDFViewer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
	<ToastContainer position='top-right' autoClose={3000} hideProgressBar newestOnTop closeOnClick={false} rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
	<Router>
		<Routes>
			<Route  path="/" element={<Home/>}/>
			<Route path="/pdfViewer/:?" element={<PDFViewer/> } />
			<Route path="/admin" element={<Login />} />
			<Route path="/admin/login" element={<Login />} />
			<Route path="/admin/*" element={<Admin/>}	/>
			<Route path="*" exact={true} element={<ErrorPage type="page" data="Not Found" />} />
		</Routes>
	</Router>
  </>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
