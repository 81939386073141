import {useEffect, useState} from 'react';
import './AddProducts.css';
import { toast } from 'react-toastify';
import { addProducts, getSingleProduct } from '../services/Api';
import { Loader } from 'rsuite';
import { useNavigate } from "react-router-dom";


export default function AddProducts(){
    const [products,setProducts] = useState({
        id: null,
        cmpny_id: '1',
        category:'',
        name: '', 
        price: '',
        stock: '100',
        min_order_qty: 1,
        max_order_qty: 100,
        image:'',
		image_prv: '',
        display: 1
    });
    const [isDisable,setDisable] = useState(true);
	const [showLoader,setLoader] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
	let navigateTo = useNavigate(); 

    useEffect(()=>{
        if(id !== null && id !== undefined && id !== '' && products.id === null){
            setLoader(true);
            getSingleProduct('id='+id)
			.then( res => {setProducts(res.data.data[0]); setLoader(false);})
			.catch((error) => {console.log("Error fetching products: ",error)});
        }
        validateForms();
	},[id,products]);


    function validateForms(){
        if(products.name!== '' && products.category !== '' && products.price !=='' && products.stock !== '' && 
            products.min_order_qty !== '' && products.max_order_qty !== '' && products.image !== '' && products.image !== '{}'){
                setDisable(false);
                console.log("products.image",products.image);
            }
        else
            setDisable(true);
        console.log("products-->",products,isDisable);
    }

    const handleInputs = (e) => {
        const { name, value,files, checked } = e.target;
        if(name !== 'image' && name !== 'display')
            setProducts({ ...products, [name]: value });
        else if(name === 'image')
            setProducts({...products,[name]:files[0],image_prv:URL.createObjectURL(e.target.files[0])})
        else if(name === 'display') {
            setProducts({...products,[name]:checked ? 1 : 0})

        }
    };

    async function handleAddProduct(){
        setLoader(true);
        const data = new FormData();
		for (const key in products) {
				data.append(key, products[key]);
				console.log("Key-->",key,"inputs-->",products[key],data)
		}
		try{
			await addProducts(data)
			.then((res) => {
				console.log("res->",res);
				console.log("res.data->",res.data); 
				if(res.data.success) {
					toast.success(res.data.message);
                    console.log("id-->",id)
                    if(id === null || id === undefined ){
                        setProducts({
                            id: null,
                            cmpny_id: '1',
                            category:'',
                            name: '', 
                            price: '',
                            stock: '100',
                            min_order_qty: 1,
                            max_order_qty: 100,
                            image:'',
                            image_prv: '',
                            display : 1
                        });
                    }
                }
				else
					toast.error(res.data.data);
			})
			.catch((err)=>{
				console.log("err->",err.response.data.error.data)
				toast.error(err.response.data.error.data);
			})
		}
		catch(err) {
			console.error('Error submitting form:', err);
			toast.error('Failed to submit form.');
		}
        finally {
            setLoader(false);
        }
    };
    function handleNavigation() {
        navigateTo('/admin/dashboard')
    }

    return (
        <div className="container-fluid AddProducts" id="AddProducts" >
			{showLoader && <div> 
				<Loader backdrop size="lg" content="Processing..." vertical height="100" width="100"/>
				</div> }
            <form method="POST" action ="/add-products" onSubmit={(e)=> e.preventDefault()} encType='multipart/form-data'>
                <div className="m-auto">
                    <div className="row row-eq-height">
                        <div className="col-12">
                            <h4>{id > 0 ? 'Edit' : 'Add'} Product</h4>
                        </div>
                    </div>
                    <div className="row row-eq-height mt-5">
                        <div className="col-12 d-flex">
                            <label className="form-label productLabel" htmlFor="name" >Product Name</label>
                            <input className="form-control productController" type="text" name="name" id="name" value={products.name} onChange={(e) =>{handleInputs(e)}} />
                        </div>
                    </div>
                    <div className="row row-eq-height mt-5">
                        <div className="col-12 d-flex">
                            <label className="form-label productLabel" htmlFor="category" >Category</label>
                            <select className="form-select productSelect" id="category" name="category" value={products.category} onChange={(e) =>{handleInputs(e)}}>
                                <option value="">Select Category</option>
                                <option value="Ground_Chakkar">Ground Chakkar</option>
                                <option value="Flower_pots">Flower Pots</option>
                                <option value="Rockets">Rockets</option>
                                <option value="Bombs">Bombs</option>
                                <option value="Sparklers">Sparklers</option>
                                <option value="Gift_Boxes">Gift Boxes</option>
                                <option value="Bijili">Bijili</option>
                                <option value="Sky_Shots">Sky shots</option>
                            </select>
                        </div>
                    </div>
                    <div className="row row-eq-height mt-5">
                        <div className="col-12 d-flex">
                            <label className="form-label productLabel" htmlFor="price" >Price</label>
                            <input className="form-control productController" type="number" name="price" id="price" value={products.price} onChange={(e) =>{handleInputs(e)}} />
                        </div>
                    </div>
                    {/*
                    <div className="row row-eq-height mt-5">
                        <div className="col-12 d-flex">
                            <label className="form-label productLabel" htmlFor="stock" >stock</label>
                            <input type="text" name="stock" id="stock" value={products.stock} onChange={(e) =>{handleInputs(e)}} />
                        </div>
                    </div>
                    <div className="row row-eq-height mt-5">
                        <div className="col-12 d-flex">
                            <label className="form-label productLabel" htmlFor="min_order_qty" >Minimum order stock</label>
                            <input type="text" name="min_order_qty" id="min_order_qty" value={products.min_order_qty} onChange={(e) =>{handleInputs(e)}} />
                        </div>
                    </div>
                    <div className="row row-eq-height mt-5">
                        <div className="col-12 d-flex">
                            <label className="form-label productLabel" htmlFor="max_order_qty" >Maximum order stock</label>
                            <input type="text" name="max_order_qty" id="max_order_qty" value={products.max_order_qty}  />
                        </div>
                    </div>
                     */}
                     
                    {/* <div className="row row-eq-height mt-5">
                        <div className="col-12 d-flex">
                            <label className="form-check-label productLabel" htmlFor="display">Show Product</label>
                            <input
                                className="form-check-input "
                                style={{padding:"10px"}}
                                name="display" id="display"
                                type="checkbox"
                                checked={products.display === 1}
                                onChange={(e)=>{handleInputs(e)}}
                            />
                        </div>
                    </div> */}
                    <div className="row row-eq-height mt-5">
                        <div className="col-12 d-flex">
                            <label className="form-label productLabel" htmlFor="image">Product Image</label>
							<input className="form-control productController" type="file" name="image" id="image" onChange={(e) =>{handleInputs(e)}}/>
                        </div>
                    </div>

                    <div className="row row-eq-height mt-5">
                        <div className="col-12 d-flex">
                            {products.image_prv && <img src={products.image_prv} alt={products.name} width="80" height="80"/>}
                            {!products.image_prv && products.image && <img src={`${process.env.REACT_APP_IMG_PATH}${products.image}`}  alt={products.name} width="80" height="80"/>}
                        </div>
                    </div>

                    <div className="row row-eq-height mt-5">
                        <div className="col-12 fw-b d-flex gap-3">
                            <button className='btn btn-success' disabled={isDisable} onClick={handleAddProduct}>{id > 0 ? 'EDIT' : 'ADD'} PRODUCT</button>
                            <button className='btn btn-primary' onClick={handleNavigation}>CLOSE</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}