import { useEffect, useState } from "react";
import './Banner.css';
import { useNavigate } from "react-router-dom";
import { getBanner, saveCompanyBanner } from "../services/Api";
import { isLoggedIn } from "../services/Authentication";
import { Loader } from 'rsuite';
import { toast } from "react-toastify";
import Login from "./Login";


export default function Banner() {
    const [inputs, setInputs] = useState([]);
    const [loader, setLoader] = useState(true);
    const [images, setImages] = useState([]);
    const [preview, setImagePreviews] = useState([]);
    const navigateTo = useNavigate();

    useEffect(()=>{
        if(isLoggedIn()){
            getBanner()
            .then(async (res)=>{
                //return await handleUploadedImages(res.data.data[0]);   
                setInputs(res.data.data[0]);
                setImages(JSON.parse(res.data.data[0].banner));  
                const savedPreview = images.map((file,index)=>{
                    console.log("file-->",file," index->",index);
                     return `${process.env.REACT_APP_IMG_PATH}${file}`;

                })
                setImagePreviews(savedPreview);
                setLoader(false)
            })
            .catch((err)=>{
                console.log("Error in fetching Banneres-->",err.response.data.message);
            })
            console.log("End of if")
        }
        
    },[loader === true]);


    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);

        // Set the files to state
        setImages((prevImages) => [...prevImages, ...files]); // Append new files to existing state

        // Generate previews
        const previews = files.map((file) => { return  URL.createObjectURL(file)});

        //set the preview to state
        setImagePreviews((prevImages) => [...prevImages, ...previews    ]); // Append new files to existing state

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        const data = new FormData();
    
        // Append selected files to FormData
        images.forEach((image) => {
            data.append('banners', image);
        });

        /*const filenames = images.map((img)=>{
            if(img.file !== undefined)
                return img.file.name
        })*/
        console.log("Images-->",images);
        //data.append("banners", images);
        for (const key in inputs) {
            console.log("Key--->",key," Inputs--->",inputs)
            data.append(key, inputs[key]);
            console.log("Key-->",key,"inputs-->",inputs[key],data)
        }
        try{
            await saveCompanyBanner(data)
            .then((res) => {
                console.log("res->",res);
                //console.log("res.data->",res.data); 
                if(res.data.success)
                    toast.success(res.data.message);
                else
                    toast.error(res.data.data);
                window.location.reload();
            })
            .catch((err)=>{
                console.log("err->",err.response.data.error.data)
                toast.error(err.response.data.error.data);
            })
        }
        catch(err) {
            console.log('Error submitting form:', err);
            toast.error('Failed to submit form.');
        }
        finally{
            setLoader(false);
        }

    }
    const handleNavigation = (e) => {
        navigateTo('/admin/dashboard');
    }

    const removeImage = (index) => {
        setImages(images.filter((_, i) => i !== index));
        setImagePreviews(preview.filter((_, i) => i !== index));
    }

    if(!isLoggedIn())
        return <Login />;
    
    return (
        <div className="container-fluid Banner  mt-3 mb-5 me-3" id="Banner" >
            <form method="POST" action ="/banners" onSubmit={(e)=> e.preventDefault()} encType='multipart/form-data'>
                <div className="m-auto">
                    <div className="row row-eq-height">
                        <div className="col-12 text-left">
                            <h4>Banner Settings</h4>
                        </div>
                    </div>
                    {loader && <Loader backdrop size="lg" vertical height="100" width="100"/>}
                    { loader === false && (<> 
                        <div className="row row-eq-height mt-3">
                            <div className="col-6 d-flex justify-content-start gap-5">
                                <label className="form-label" htmlFor="banners">Choose a Image file to add in the banner</label>
                                <input id="banners" name="banners" className="" type="file" multiple accept="image/*" onChange={handleImageChange}   />
                            </div>
                            <div className="col-6 d-flex justify-content-end gap-5">    
                                <button type="submit" onClick={handleSubmit} className="btn btn-success py-2">SAVE</button>
                                <button type="submit" onClick={handleNavigation} className="btn btn-primary py-2">CANCEL</button>
                            </div>
                        </div>
                        <div className="row row-eq-height mt-3">
                            <div className="col-12 d-flex">
                                <label className="form-label" htmlFor="preview">Preview Banners</label>
                            </div>
                        </div>
                        <div className="row row-eq-height rounded ms-1" style={{border:"3px solid darkcyan",height : "60dvh", overflow:"scroll"}}>
                            <div>
                                {
                                    preview.map((img,index)=>{
                                        return (<div className="col-12" key={index}>
                                            <div className="fs-5  mt-4 d-flex gap-5 p-1" style={{border:"3px solid darkcyan"}} >
                                                <div>Banner : {index+1} </div>
                                                <div>
                                                    <i key={index} className="fa fa-trash fs-4 text-danger" title="Remove banner image" onClick={(e)=>{removeImage(index)}}></i>
                                                </div>
                                            </div>
                                            <img key={index} src={img} alt="banner" style={{width:"95dvw",height:"400px", border :"2px solid darkcyan"}}/>
                                        </div>)

                                    })
                                }

                            </div>
                        </div>
                        {/*<div className="row row-eq-height mt-5">
                            <div className="col-12 d-flex gap-4">
                                <button type="submit" onClick={handleSubmit} className="btn btn-success py-2 btn-lg">SAVE</button>
                                <button type="submit" onClick={handleNavigation} className="btn btn-primary py-2 btn-lg">CANCEL</button>
                            </div>
                        </div> */}
                    </>)}

                </div>
            </form>
        </div>
    );
}