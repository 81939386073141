import { useEffect, useState } from "react";
import { isLoggedIn } from "../services/Authentication";
import Login from "./Login";
import { decodeData, encodeData, getCompany, saveCompany } from "../services/Api";
import { toast } from "react-toastify";
import ErrorPage from "../components/ErrorPage";
import './Profile.css';
import { Loader } from 'rsuite';
import { useNavigate } from "react-router-dom";

export default function Profile(){
	const [inputs, setInputs] = useState({});
    const [loader,setLoader] = useState(true);
    const navigateTo = useNavigate();
	useEffect(()=>{
		if(isLoggedIn()) {
			getCompany()
			.then((res) => {
				const datas = res.data.data[0]
				datas.password = decodeData(datas.password);
				setInputs(datas);
                setLoader(false);
			})
			.catch((err)=>{
				toast.error("Error in getting profile:",err);
				return <ErrorPage type="error" data="Not Found" />
			})
		}
	},[]);
	const handleInputs = (e) => {
		const { name, value } = e.target;
		setInputs({ ...inputs, [name]: value });
	};

	const handleFileChange = (e) => {
		setInputs({ ...inputs, logofile: e.target.files[0],logo_prv:URL.createObjectURL(e.target.files[0])});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
        setLoader(true);
		if(inputs.password !== inputs.confirm_password ){
            setLoader(false);
			toast.error("Password mismatch. Password and confirm password should be same! to save the form");
			return false;
		}

		const data = new FormData();
		for (const key in inputs) {
			if(key === 'password')
			{
				data.append(key, encodeData(inputs[key]));
			}	
			else {
				data.append(key, inputs[key]);
				console.log("Key-->",key,"inputs-->",inputs[key],data)
			}
		}
		try{
			await saveCompany(data)
			.then((res) => {
				console.log("res->",res);
				console.log("res.data->",res.data); 
				if(res.data.success)
					toast.success(res.data.message);
				else
					toast.error(res.data.data);
			})
			.catch((err)=>{
				console.log("err->",err.response.data.error.data)
				toast.error(err.response.data.error.data);
			})
		}
		catch(err) {
			console.error('Error submitting form:', err);
			toast.error('Failed to submit form.');
		}
        finally{
            setLoader(false)
        }
	};

    function handleClose() {
        navigateTo('/admin/dashboard');
    }
    if(!isLoggedIn())
        return <Login />;

    return (
        <div className="container-fluid profileform mt-3 mb-5 me-3">
            <h4>Company Profile </h4>
            {loader && <Loader backdrop size="lg"  vertical height="100" width="100"/>}
            {inputs.id !== undefined && loader === false && (
            <form method="POST" onSubmit={(e)=> { e.preventDefault();}} className="" encType="multipart/form-data">
                <div className="input-group col-md-12">
                    <div className="col-md-2">
                            <label htmlFor="name" className="form-label">Name</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="name" 
                            name="name" 
                            type="text" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.name} 
                        />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <label htmlFor="address" className="form-label">Address</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="address" 
                            name="address" 
                            type="text" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.address}
                        />
                    </div>
                </div>
                <div className="input-group col-md-12">
                    <div className="col-md-2">
                        <label htmlFor="street" className="form-label">Street</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="street" 
                            name="street" 
                            type="text" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.street}
                        />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <label htmlFor="city" className="form-label">City</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="city" 
                            name="city" 
                            type="text" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.city}
                        />
                    </div>
                </div>
                <div className="input-group col-md-12">
                    <div className="col-md-2">
                        <label htmlFor="state" className="form-label">State</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="state" 
                            name="state" 
                            type="text" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.state}
                        />
                    </div>
                    <div className="col-md-1"></div>
                    {/*<div className="col-md-2">
                        <label htmlFor="country" className="form-label">Country</label>
                    </div>
                    <div className="col-md-3">
                        <input id="country" name="country" type="text" className="form-control" onChange={(e)=> {handleInputs(e)}} value={inputs.address}/>
                    </div>*/}
                    <div className="col-md-2">
                        <label htmlFor="pincode" className="form-label">Pincode</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="pincode" 
                            name="pincode" 
                            type="text" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.pincode}
                        />
                    </div>
                </div>
                <div className="input-group col-md-12">
                    <div className="col-md-2">
                        <label htmlFor="discount" className="form-label">Discount (%)</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="discount" 
                            name="discount" 
                            type="number" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.discount}
                        />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <label htmlFor="website" className="form-label">Website</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="website" 
                            name="website" 
                            type="text" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.website}
                            disabled
                            title="website Address"
                        />
                    </div>
                </div>
                <div className="input-group col-md-12">
                    <div className="col-md-2">
                        <label htmlFor="mobile_primary" className="form-label">Mobile (Primary)</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="mobile_primary" 
                            name="mobile_primary" 
                            type="text" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.mobile_primary}
                        />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <label htmlFor="mobile_secondary" className="form-label">Mobile (Secondary)</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="mobile_secondary" 
                            name="mobile_secondary" 
                            type="text" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.mobile_secondary}
                        />
                    </div>
                </div>
                <div className="input-group col-md-12">
                    <div className="col-md-2">
                        <label htmlFor="email" className="form-label">Email</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="email" 
                            name="email" 
                            type="email" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.email}
                        />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <label htmlFor="logoFile" className="form-label">Logo</label>
                    </div>
                    <div className="col-md-3 d-flex">
                        <input 
                            id="logofile" 
                            name="logofile" 
                            type="file" 
                            onChange={(e)=> {handleFileChange(e)}}
                        />
                        {inputs.logo !== '' && !inputs.logofile && ( <><img 
                            src={`${process.env.REACT_APP_IMG_PATH}${inputs.logo}`} 
                            alt="logo" 
                            width="80" 
                            height="80"/>
                        </>)}
                        {inputs.logofile && (<>
                            <img 
                                src={inputs.logo_prv}
                                alt="logo" 
                                width="80" 
                                height="80"
                            />
                        </>)

                        }
                    </div>
                </div>
                <div className="input-group col-md-12">
                    <div className="col-md-2">
                        <label htmlFor="password" className="form-label">Password</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="password" 
                            name="password" 
                            type="password" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.password}
                        />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
                    </div>
                    <div className="col-md-3">
                        <input 
                            id="confirm_password" 
                            name="confirm_password" 
                            type="password" 
                            className="form-control" 
                            onChange={(e)=> {handleInputs(e)}} 
                            value={inputs.confirm_password}
                        />
                        {inputs.confirm_password === '' && <span className="text-danger fs-6" style={{fontWeight:"bold"}}>Please re-enter the password</span>}
                    </div>
                </div>
                <div className="input-group col-md-12 d-flex gap-3">
                    <input type="button" className="rounded btn btn-success py-2 btn-lg " value="SAVE" onClick={(e)=>{handleSubmit(e)}} disabled={inputs.password !== inputs.confirm_password}/>
					<input type="button" className="rounded btn btn-primary py-2 btn-lg " value="CANCEL" onClick={(e)=>{handleClose(e)}}/>
                </div>
            </form>
            )}
        </div>
    );
}