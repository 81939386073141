import { useEffect, useState } from "react";
import './ImageViewer.css';

export default function ImageViewer(props) {

    const [image,setImage] = useState("");
	const [isOpen,setIsOpen] = useState(true);
    useEffect(()=>{
		setIsOpen(props.isOpen);
        if(props.image!=='' && isOpen ) {
			setImage(props.image);
			props.close('open');
		}
		if(!isOpen){
			props.close('close');
		}
    },[props,isOpen]);

	if(image === ''){
		return null;
	}
    return (
        <div id="ImageViewer" className="ImageViewer">
			<div className="ImgpopupHeader d-flex justify-content-between">
				<div className="text-start align-middle">{props.name}</div>
				<button onClick={()=>{console.log("Setting as empty for an image while close");setImage(""); setIsOpen(false)}} className="btn btn-secondary text-end" ><i className="fa fa-close fs-6"></i></button>
			</div>
			<div  className="imageContainer mt-2 mb-2">
				{image && (
					<img src={image} alt="Product Img"/>
				)}
			</div>
        </div>
    );
}