import React, { useEffect, useState,  } from "react";
import'./Body.css';
import ImgSlider from "./Imgslider";
import diwaliBanner from '../asserts/images/diwali-2.png';
import diwaliSale from '../asserts/images/diwali-3.png';
import company from '../asserts/images/company-1.jpeg';
import company2 from '../asserts/images/company-2.jpeg';
import { getBanner } from "../services/Api";
import ImageSlider from "./ImageSlider";

export default function Body(){
	const [images,setImages] = useState([]);
	useEffect(()=>{
		getBanner()
		.then((res)=>{
			setImages(JSON.parse(res.data.data[0].banner));  
		})
		.catch((err)=>{
			console.log("Error in fetching Banneres-->",err.response.data.message);
		})
	},[])
    const images_backup = [
        {
          imgURL: company,
          imgAlt: "img-1"
        },
        {
          imgURL: diwaliBanner,
          imgAlt: "img-2"
        },
        {
          imgURL: company2,
          imgAlt: "img-3"
        },
        {
          imgURL: diwaliSale,
          imgAlt: "img-4"
        }
      ];    
	  return (
        <section id="Body" className="Body">
            {/*<ImgSlider>
                {images.map((image, index) => {
                return <img key={index} src={`${process.env.REACT_APP_IMG_PATH}${image}`} alt={image} />;
                })}
            </ImgSlider>*/}
			{images.length > 0 &&
				<ImageSlider images={images} />
			}
        </section>
    );
}