import {  useEffect, useState } from "react";
import {  deleteProduct, getProducts, getSingleProduct } from '../services/Api';
import './AdminListProducts.css';
import ImageViewer from "./ImageViewer";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { toast } from "react-toastify";

export default function AdminListProducts() {
    const [isLoader,setIsLoader] = useState(true);
	const [loader,setLoader] = useState(true);
	const [productList,setProductList] = useState([]);
	const [zoomImg,setZoomImg] = useState(false);
	const [image,setImage] = useState("");
	const [imageName,setImageName] = useState("");
	const [search,setSearch] = useState({keyword:""});
	const [keyword,setKeyword] = useState("");
	let navigateTo = useNavigate(); 

	useEffect(()=>{
		getProducts()
		.then( res => { setProductList(res.data.data); setIsLoader(false); setLoader(false)})
		.catch((error) => {console.log("Error fetching products: ",error)})

	},[]);

	function handleZoomInImg(item) {
		//var fullPath = window.location.origin+'/' + item.image;
		var fullPath = process.env.REACT_APP_IMG_PATH+item.image;
		setImage(fullPath);
		setImageName(item.name);
		setZoomImg(true);
	}

	function handleZoomOutImg(type) {
		if(type === 'close')
			setImage("");
	}
	async function clearSearch(){
		setKeyword("");
		await getProducts()
		.then( res => { setProductList(res.data.data); setIsLoader(false);})
		.catch((error) => {console.log("Error fetching products: ",error)});
	}
	async function handleProducts(e){
		console.log("Search products-->",search);
		console.log("Search products keyword-->",keyword);
		setIsLoader(true);
		if(keyword !== undefined && keyword !== '') {
			await getSingleProduct(keyword)
			.then( res => { setProductList(res.data.data); setIsLoader(false);})
			.catch((error) => {console.log("Error fetching products: ",error)});
		}
		else
		{
			await getProducts()
			.then( res => { setProductList(res.data.data); setIsLoader(false);})
			.catch((error) => {console.log("Error fetching products: ",error)});
		}
		
	}

	function handleNavigate(index){
		console.log("Index-->",index)
		if(index !== '' && index !== undefined)
			navigateTo('/admin/addProduct?&id='+index);
		else
			navigateTo('/admin/addProduct');
	}

	async function handleDelete(index){
		//setIsLoader(true);
		if(window.confirm("Are you sure you want to delete a product ?")) {
			try {
				await deleteProduct(index)
				//await getProducts()
				.then( res => { handleProducts(); toast.success(res.data.message); })
				.catch((error) => { toast.error(error.response.data.error.message+" : "+error.response.data.error.data); setIsLoader(false); console.log("Error while remove product: ",error)});
			} catch (error) {
				toast.error("Unable to Delete!",error);
				setIsLoader(false);
			}

		}
		else {
			setIsLoader(false);
		}
	}

	function handleKeyword(e){
		//setSearch({...search,keyword:e.target.value});
		console.log("e.target.value-->",e.target.value);
		setKeyword(e.target.value);
	}
	const productsListUI = productList.map((item,index)=>{
		return (
				<tr key={item.id}>
					<td>{index+1}</td>
					<td>{item.name}</td>
					<td>{item.category}</td>
					<td>{item.price}</td>
					{/* <td>{item.display === 1 ? "Show" : "Hide"}</td> */}
					<td><img 
							//src={window.location.origin+'/' + item.image} 
							src={`${process.env.REACT_APP_IMG_PATH}${item.image}`}
							alt={item.name} 	
							width="50px" 
							height="50px" 
							onClick={()=>{handleZoomInImg(item)}}
							style={{"cursor":"pointer","objectFit":"contain"}}
						/>
					</td>
					<td>
						<button className="fa fa-edit fs-4 text-primary" onClick={(e)=>{handleNavigate(item.id)}}></button>
						<button className="fa fa-trash fs-4 text-danger" onClick={(e)=>{handleDelete(item.id)}}></button>
					</td>
				</tr>
		);
	});
    return (
		<div className="container-fluid mt-3 mb-5 me-3 adminListProducts">
			{loader === true && <Loader backdrop size="lg"  vertical height="100" width="100"/> }
			<div className="d-flex gap-5">
				<h4>Products List</h4>
				<input type="button" className="btn btn-success py-2" value="ADD PRODUCTS" onClick={(e)=>{handleNavigate()}}/>
			</div>
			<div className="filter m-10 mt-4" >
				<div className="d-flex gap-3">
					<input type="text" className="form-control py-2 " placeholder="search a product" value={keyword} onChange={handleKeyword}/>
					<div style={{"width":"2%"}}></div>
					<input type="button" className="btn btn-success py-2" value="SEARCH" onClick={(e)=>{handleProducts(e)}}/>
					<input type="button" className="btn btn-primary py-2 " value="CLEAR" onClick={(e)=>{clearSearch(e)}}/>
					
				</div>
			</div>
			<div className="table-responsive" style={{width:"100%",minHeight: productList.length === 0 ?"70dvh":"",maxHeight:"70dvh",overflowY:"scroll","marginTop":"1%"}}>
				<table className="table table-striped  table-bordered table-hover " >
					<thead className="tHead thead-dark">
						<tr style={{"fontWeight":"bolder"}}>
							<td>#</td>
							<td>Name</td>
							<td>Category</td>
							<td>Price</td>
							{/* <td>Show Products</td> */}
							<td>Image</td>
							<td>Actions</td>
						</tr>
					</thead>
					<tbody>
						{isLoader && productList.length > 0 ? <tr><td style={{"textAlign":"center"}} colSpan="6">Loading....</td></tr> : productsListUI }
						{productList.length === 0 && <tr><td style={{"textAlign":"center"}} colSpan="6">No such product</td></tr> }
					</tbody>
				</table>
			</div>
			{zoomImg && <><ImageViewer close={handleZoomOutImg} image={image} name={imageName} isOpen="true"/></>}
		</div>
    );
};