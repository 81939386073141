import AdminListProducts from "../components/AdminListProducts";
import Login from "./Login";
import { isLoggedIn } from "../services/Authentication";
import './Dashboard.css';

export default function Dashboard() {
    console.log("Entered Dashboard component. isLoggedIn-->",isLoggedIn())
    if(!isLoggedIn())
        return <Login />;
    
    return (
        <div className="Dashboard">
            <AdminListProducts />
        </div>
    );
}