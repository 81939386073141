import './Admin.css';
import { Routes,Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AdminHeader from '../components/AdminHeader';
import { isLoggedIn } from '../services/Authentication';
import Login from './Login';
import Dashboard from './Dashboard';
import Profile from './Profile';
import ErrorPage from '../components/ErrorPage';
import FormComponent from './SamplePage';
import Footer from '../components/Footer';
import { getCompany } from '../services/Api';
import AddProducts from '../components/AddProducts';
import Banner from './Banner';

export default function Admin(){
    const [company,setCompany] = useState([]);
    const [error,setError] = useState();
    const [errorData,setErrorData] = useState("");
	console.log("Entered Admin Component. isLoggedIn-->",isLoggedIn());
    useEffect(()=>{
        getCompany()
		.then( res => { setCompany(res.data.data); setError(false) })
		.catch((error) => {console.log("Error fetching company : ",error); setError(true); setErrorData(error.response.data.message)});
    },[]);
    
    if(!isLoggedIn())
        return <Login />;
    else
        

    return (
        <div className="Admin" id="Admin">
			{error && (
                <>
                    <ErrorPage type="site" data={errorData} />
                </>
            )}
            {error === false && (
                <>
                    <div style={{height:"10dvh"}}>
                        <AdminHeader  />
                    </div>
                    <div>
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/form" element={<FormComponent/>} />
                            <Route path="addProduct" element={<AddProducts/>} />
                            <Route path="addProduct/:id" element={<AddProducts/>} />
                            <Route path="banner" element={<Banner/>} />
                            <Route path="*" element={<ErrorPage type="page" data="Not Found" />} />
                        </Routes>
                    </div>
                    <div>
                        <Footer company={company} />
                    </div>
                </>
            )}
        </div>
    );
}