import './ErrorPage.css';

export default function ErrorPage(props){
    console.log("Error Page called")
    const {type,data} = props;

    return (
        <div className="ErrorContainer container-fluid text-center">
            <div className='ErrorBlock'>

                {type === 'error' && 
                (<div className='Error'>
                    <h1 className='text-danger'>Error: {data}. Please try again later.</h1>
                </div>)}

                {type === 'page' &&
                (<div className="pageError">
                    <h1 className='text-danger'> :( 404 Page not Found!</h1>
                    <h3>Please enter correct url</h3>
                </div>)}

                {type === 'site' && 
                (
                    <div className="siteError">
                        <h1 className='text-success'> COMING SOON!</h1>
                        <h3>{data}</h3>
                    </div>
                )}
            </div>
        </div>
    );
}