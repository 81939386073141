import React, { useEffect, useState } from "react";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import Contact from "./Contact";
import Products from "./Products";
import { getCompany, getProducts, getSingleProduct } from "../services/Api";
import ErrorPage from "./ErrorPage";
import { Loader } from "rsuite";
import './Home.css'


export default function Home (){
    const [products,setProductList] = useState([]);
    const [searchProducts,setSearchProductList] = useState([]);
    const [company,setCompany] = useState([]);
    const [error,setError] = useState();
    const [errorData,setErrorData] = useState("");
	const [loader,setLoader] = useState(true);
    useEffect(()=>{
        getProducts()
		.then( res => { setProductList(res.data.data); setSearchProductList(res.data.data); setError(false) })
		.catch((error) => {
            console.log("Error fetching products: ",error.response.data);
            setError(true);
            return setErrorData(error.response.data.message);
        });

        getCompany()
		.then( res => { setCompany(res.data.data); setError(false) })
		.catch((error) => {
            //console.log("Error fetching company : ",error.response.data);
            setError(true);
            return setErrorData(error.response.data.message);
        });
		setLoader(false);
    },[]);

    const [keyword,setKeyword] = useState("")
    const handleSubmitSearch = async () =>{
        setLoader(true);
        
		if(keyword !== undefined && keyword !== '') {
			await getSingleProduct(keyword)
			.then( res => { setSearchProductList(res.data.data); setLoader(false); setError(false);})
			.catch((error) => {console.log("Error fetching products: ",error);setError(true);
				return setErrorData(error.response.data.message)});
		}
		else
		{
			await getProducts()
			.then( res => { setSearchProductList(res.data.data); setLoader(false); setError(false) })
			.catch((error) => {
				console.log("Error fetching products: ",error.response.data);
				setError(true);
				return setErrorData(error.response.data.message);
			});
		}

    }
    
    return (
       <div style={{width:"100%","overflow":"hidden"}} id="Home" className="Home">
			{loader && <Loader backdrop size="lg" vertical height="100" width="100"/>}
           {error && 
                (
                <>
                    <ErrorPage type="site" data={errorData} />
                </>
                )
            } 
               
            {error === false && 
                ( 
                <div style={{backgroundColor:"aliceblue"}}>
                    <Header company={company}/>
                    <Body />
                    <div id="Search" className="d-flex gap-3 mx-3 py-3 my-2 rounded" style={{border:"4px solid darkcyan"}} >
						<div className="py-3 ps-2 fs-5" style={{fontWeight:"bold"}}>Search </div>
                        <input type="text" className="form-control py-2 m-2" placeholder="Search a product" onChange={(e)=>{setKeyword(e.target.value)}} value={keyword} style={{width:"95%"}} />
                        <button type="submit" onClick={(e)=>{handleSubmitSearch(e);}} className="btn btn-success py-2 m-2"><i className="fa fa-search"></i></button>
                    </div>
                    <Products products={products} searchProducts={searchProducts}/>
                    <Contact company={company}/>
                    <Footer company={company}/>
                </div>
            )}
        </div>
    );
};