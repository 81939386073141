import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCompany, saveCompany } from '../services/Api';
import { isLoggedIn } from '../services/Authentication';
import Login from './Login';

const FormComponent = () => {
  const [inputs, setInputs] = useState({});
  useEffect(()=>{
	if(isLoggedIn()){
		getCompany()
		.then((res)=>{
			const datas = res.data.data[0]
			setInputs(datas);
		})
		.catch((err)=>{

		})
	}
  },[]);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleFileChange = (e) => {
    setInputs({ ...inputs, logofile: e.target.files[0],logo_prv:URL.createObjectURL(e.target.files[0])});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in inputs) {
       data.append(key, inputs[key]);
       console.log("Key-->",key,"inputs-->",inputs[key],data)
    }
   try{
        await saveCompany(data)
        .then((res) => {
            console.log("res->",res);
            console.log("res.data->",res.data); 
            if(res.data.success)
                toast.success(res.data.message);
            else
                toast.error(res.data.data);
        })
        .catch((err)=>{
            console.log("err->",err.response.data.error.data)
            toast.error(err.response.data.error.data);
        })
   }
   catch(err) {
        console.error('Error submitting form:', err);
        toast.error('Failed to submit form.');
   }
  };
  if(!isLoggedIn())
	return <Login />;

return (
	<div className="container-fluid profileform pt-3 pb-5">
		<h3>Company Profile </h3>

		{inputs.id !== undefined && (
		<form method="POST" onSubmit={(e)=> { e.preventDefault();}} className="ps-5" encType="multipart/form-data">
			<div className="input-group col-md-12">
				<div className="col-md-2">
						<label htmlFor="name" className="form-label">Name</label>
				</div>
				<div className="col-md-3">
					<input 
						id="name" 
						name="name" 
						type="text" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.name} 
					/>
				</div>
				<div className="col-md-1"></div>
				<div className="col-md-2">
					<label htmlFor="address" className="form-label">Address</label>
				</div>
				<div className="col-md-3">
					<input 
						id="address" 
						name="address" 
						type="text" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.address}
					/>
				</div>
			</div>
			<div className="input-group col-md-12">
				<div className="col-md-2">
					<label htmlFor="street" className="form-label">Street</label>
				</div>
				<div className="col-md-3">
					<input 
						id="street" 
						name="street" 
						type="text" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.street}
					/>
				</div>
				<div className="col-md-1"></div>
				<div className="col-md-2">
					<label htmlFor="city" className="form-label">City</label>
				</div>
				<div className="col-md-3">
					<input 
						id="city" 
						name="city" 
						type="text" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.city}
					/>
				</div>
			</div>
			<div className="input-group col-md-12">
				<div className="col-md-2">
					<label htmlFor="state" className="form-label">State</label>
				</div>
				<div className="col-md-3">
					<input 
						id="state" 
						name="state" 
						type="text" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.state}
					/>
				</div>
				<div className="col-md-1"></div>
				{/*<div className="col-md-2">
					<label htmlFor="country" className="form-label">Country</label>
				</div>
				<div className="col-md-3">
					<input id="country" name="country" type="text" className="form-control" onChange={(e)=> {handleInputs(e)}} value={inputs.address}/>
				</div>*/}
				<div className="col-md-2">
					<label htmlFor="pincode" className="form-label">Pincode</label>
				</div>
				<div className="col-md-3">
					<input 
						id="pincode" 
						name="pincode" 
						type="text" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.pincode}
					/>
				</div>
			</div>
			<div className="input-group col-md-12">
				<div className="col-md-2">
					<label htmlFor="mobile_primary" className="form-label">Mobile (Primary)</label>
				</div>
				<div className="col-md-3">
					<input 
						id="mobile_primary" 
						name="mobile_primary" 
						type="text" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.mobile_primary}
					/>
				</div>
				<div className="col-md-1"></div>
				<div className="col-md-2">
					<label htmlFor="mobile_secondary" className="form-label">Mobile (Secondary)</label>
				</div>
				<div className="col-md-3">
					<input 
						id="mobile_secondary" 
						name="mobile_secondary" 
						type="text" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.mobile_secondary}
					/>
				</div>
			</div>
			<div className="input-group col-md-12">
				<div className="col-md-2">
					<label htmlFor="email" className="form-label">Email</label>
				</div>
				<div className="col-md-3">
					<input 
						id="email" 
						name="email" 
						type="email" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.email}
					/>
				</div>
				<div className="col-md-1"></div>
				<div className="col-md-2">
					<label htmlFor="logoFile" className="form-label">Logo</label>
				</div>
				<div className="col-md-3">
					<input 
						id="logofile" 
						name="logofile" 
						type="file" 
						onChange={(e)=> {handleFileChange(e)}}
					/>
					{inputs.logo !== '' && !inputs.logofile && ( <><img 
						src={`${process.env.REACT_APP_IMG_PATH}${inputs.logo}`} 
						alt="logo" 
						width="80" 
						height="80"/>
					</>)}
					{inputs.logofile && (<>
						<img 
							src={inputs.logo_prv}
							alt="logo" 
							width="80" 
							height="80"
						/>
					</>)

					}
				</div>
			</div>
			<div className="input-group col-md-12">
				<div className="col-md-2">
					<label htmlFor="password" className="form-label">Password</label>
				</div>
				<div className="col-md-3">
					<input 
						id="password" 
						name="password" 
						type="password" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.password}
					/>
				</div>
				<div className="col-md-1"></div>
				<div className="col-md-2">
					<label htmlFor="confirm_password" className="form-label">Confirm Password</label>
				</div>
				<div className="col-md-3">
					<input 
						id="confirm_password" 
						name="confirm_password" 
						type="password" 
						className="form-control" 
						onChange={(e)=> {handleInputs(e)}} 
						value={inputs.confirm_password}
					/>
				</div>
			</div>
			<div className="input-group col-md-12">
				<input type="button" className="btn btn-lg btn-success" value="SAVE" onClick={(e)=>{handleSubmit(e)}}/>
			</div>
		</form>
		)}
	</div>
);
}

  export default FormComponent;
