export const storeToken = (data)=>{
    localStorage.setItem('Token',data)
}

export const getToken = () =>{
    return localStorage.getItem('Token');
}

export const removeToken = () =>{
     localStorage.removeItem('Token')
}