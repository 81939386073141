import React, {useEffect, useState} from "react";
import './Products.css';
import Counter from './Counter';
import Cart from './Cart';
//import ListProducts from "./ListProducts";
import {Loader} from "rsuite";
import 'rsuite/dist/rsuite.min.css';


export default function Products(props){
    const [productCount,setProductCount] = useState(0);
    const [productCategoryList, setProductCategoryList] = useState([]);
    const [IsLoader,setLoader] = useState(false);
    const [LoaderContent,setLoaderContent] = useState("");
    const [message,setMessage] = useState("");
    const [searchProduct,setSearchProduct] = useState([]);

    useEffect(()=>{
        console.log("props.searchProducts-->",props.searchProducts," length-->",props.searchProducts.length," searchProduct-->",searchProduct)
        if(props.searchProducts.length > 0){
            setSearchProduct([]);
            props.searchProducts.map((sp)=>{
                setSearchProduct((pv)=>{
                    return [...pv,sp.id];
                })
            })
            console.log("searchProduct-->",searchProduct)
        }
        else{
            setSearchProduct([]);
        }
        if(props.searchProducts.length === 0) {
            setLoader(false);
            return setMessage("No such Product!");
        }
        else{
            setMessage("");
        }
        if(productCategoryList.length === 0 || props.products.length !== productCategoryList.length) {
            setMessage("")
            setLoader(true);
            const getProducts = async ()=>{
                await setProductCategoryList(props.products);
             }
             getProducts();
             if(productCategoryList.length === 0){
                setTimeout(()=>{
                    //setLoader(false);
                    setLoaderContent("Sorry! Unable to load the Products. Please try again later!");
                },3000);
             }
        }
        else {
            setLoader(false);
        }
        const filteredProductList = productCategoryList.filter((prod)=>
            {
                if(prod.quantity > 0) 
                    return prod;
                else
                    return false;
            }
        );
        const quantityArray = filteredProductList.map(p => p.quantity);
        let sumElement =0;
        for (let index = 0; index < quantityArray.length; index++) {
            sumElement = sumElement + parseInt(quantityArray[index]);
            setProductCount(sumElement);
        }
    },[props.products,props.searchProducts,productCategoryList]);



	async function handleProductQuantity (counterData,index){
		await setProductCategoryList((prs) => {
            return prs.map((pr) => {
                return pr.id === index ? { ...pr, quantity: counterData } : pr;
            });
        });
	}
    const getProductCategoryList = productCategoryList.map(
        (item) => {
            {/* HORIZONTAL LIST
             <div className="card p-1 m-2 productBoxH" key={item.id} style={{display:"none"}} >
                 <div className="row">
                     <div className="col-md-6">
                         <img style={{width:"30dvw", overflow:"scroll"}} src={`${process.env.REACT_APP_IMG_PATH}${item.image}`} alt={item.name} className="card-img-top img-fluid" />
                     </div>
                     <div className="col-md-6 mt-auto mb-auto">
                         <div className="card-body">
                             <div className="card-title"><Counter getProductQuantity={handleProductQuantity} index={item.id}/> </div>
                             <div className="card-content" style={{fontWeight:"bold"}}>{item.name} <br/> <span style={{"color":"green"}}> Price: <i style={{"fontWeight":"normal","fontSize":"18px"}} className="fa fa-rupee"></i>{item.price}.00</span></div>
                         </div>
                     </div>

                 </div>


             </div>*/}
            return (
                    <div className="card productBox  col-md-3 col-sm-12" key={item.id} style={{
                        display: searchProduct.includes(item.id) ? 'block':'none'
                    }}>
                        <img src={`${process.env.REACT_APP_IMG_PATH}${item.image}`} alt={item.name} className="card-img-top img-fluid" />
                        <div className="card-body">
                            <div className="card-title"><Counter getProductQuantity={handleProductQuantity} index={item.id}/> </div>
                            <h5 className="card-content">{item.name} <br/> <span style={{"color":"green"}}> Price: <i style={{"fontWeight":"normal","fontSize":"18px"}} className="fa fa-rupee"></i>{item.price}.00</span></h5>
                        </div>
                        {/*<div className="card-content">
                            <div style={{"fontWeight":"bold","fontSize":"18px"}}></div>
                        </div>*/}
                    </div>
            );
        }
    );
    

    return(
        <section id="Products" className="Products">
            {/*<h4 style={{"textAlign":"center","fontWeight" : "bolder"}}>Our Products</h4>*/}
                     
            <div className="container-fluid" style={{"margin":"0px auto"}}>
                <div className="row row eq-height">
                {message !=="" ? <>
                    <div className="text-center py-5" >
                            <h5>{message}</h5>
                    </div>
                </> : 
                        (<>{productCategoryList.length > 0 && getProductCategoryList }
                        {productCategoryList.length === 0 && IsLoader && <Loader size="lg" backdrop content="Products Loading..." />}
                        {/*productCategoryList.length === 0 && !IsLoader && LoaderContent!== '' && <div><h4>{LoaderContent}</h4></div>*/}
                        </>)
                }
                </div>
            </div>
            <Cart productCount={productCount} productList={productCategoryList}/>
            {/*<ListProducts  productList={productCategoryList} productCount={productCount}/>*/}
        </section>
    );
}