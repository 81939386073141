import { useEffect, useState } from "react";
import { getOrderPDF } from "../services/Api";
import { Loader } from 'rsuite';
import parse from 'html-react-parser';
import './PDFViewer.css'

export default function PDFViewer() {
    const [pdf,setPDF] = useState("");
    
    const [showLoader,setLoader] = useState(true);
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    useEffect(()=>{
        getOrderPDF('id='+id)
			.then( res => {setPDF(res.data.data[0].pdf); setLoader(false); })
			.catch((error) => {console.log("Error fetching PDF: ",error)});
    },[id])

    const html = parse(pdf);
    const download = ()=>{
        window.print();
    }
    
    return (
        <>
        { showLoader ? <Loader size="lg" backdrop content="" /> : 
            (   <div>
                    <div style={{"margin":"1%","textAlign":"right","right":"2%"}}>
                        <button title="Download PDF" className="fa fa-download printButton"  style={{fontSize:"36px",marginRight:"2%","color":"green"}} onClick={download}></button>

                    </div>
                    <div>
                        {html}
                    </div>
                </div>
             )
        }
        </>
    );
}