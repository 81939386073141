import React, { useEffect, useState } from 'react';
import './Login.css';
import { toast } from 'react-toastify';
import { getLoginCrendentials } from '../services/Api';
import { storeToken } from '../services/Storage';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../services/Authentication';
import { Loader } from 'rsuite';
import Dashboard from './Dashboard'

export default function Login(){
	const [inputs,setInputs] = useState({"email":"","password":""});
    const [loader,setLoader] = useState(false);
	const [isDisable,setDisable] = useState(false);
	const navigate = useNavigate();

	useEffect(()=>{
		if(isLoggedIn()) {
			return navigate('/admin/dashboard')
		}
	},[isLoggedIn()]);

	function handleLoginCredentials (e) {
		var key = e.target.name;
		if(e.target.value !== ''){
			setInputs({...inputs, [key] : e.target.value});
		}
		setDisable(false);
	}

    function handleLogin (e) {
		setLoader(true);
		if(inputs.email !== '' && inputs.password !== '') {
			setDisable(false)
			getLoginCrendentials(inputs)
        	.then( res => { 
				if(res.data.userToken !== undefined) {
					storeToken(res.data.userToken);
					toast.success(res.data.message);
					setLoader(false);
					return navigate('/admin/dashboard');
					
				}
				else{
					toast.error("Error while login");
					setLoader(false);
				}
			})
        	.catch((error) => {
				setLoader(false);
				toast.error(error.response.data.message)
			});

		}
		else {
			toast.warning("Please enter Email and Password.")
			setLoader(false)
			setDisable(true);
		}
    }

    return (
        <div className="">
			{loader && <Loader size="lg" backdrop content="" />}
                <form method="POST" action ="/login" onSubmit={(e)=> e.preventDefault()} encType='application/json' className='loginForm' >
					<div className='mb-3 fw-b text-center'>
						<h2>Sign in </h2>
					</div>
                    <div className='mb-3'>
                        <label className='form-label' htmlFor="email">Email:</label>
                        <input className="form-control" type="text" name='email' onBlur={(e)=> { handleLoginCredentials(e)}} />
                    </div>
					<div className='mb-3'>
                        <label className='form-label' htmlFor="password">Password:</label>
                        <input className="form-control" type="password" name='password' onBlur={(e)=> { handleLoginCredentials(e)}} />
                    </div>
					<div className='mb-3 text-center'>
                        <input className="btn btn-success" type="button" name='submit' value="LOGIN" disabled={isDisable} onClick={(e)=>{ handleLogin(e)}} />
                    </div>
                </form>
        </div>
    );
}