import React from "react";
import './Header.css';
import { useState, useEffect } from "react";

export default function Header(props){
     const [toggleMenu, setToggleMenu]  = useState(false);
     const [company,setCompany] = useState([]);

    useEffect(()=>{
        if(company.length === 0) {
            const getCompany = async ()=>{
                await setCompany(props.company);
             }
             getCompany();
        }
    },[props,company.length]);

    return(
        <section id="Header" className="Header">
                <div className="row d-flex">
                    <div className="col-md-3 navMobileClass" style={{"display":"none"}}>
                        <button onClick={() => setToggleMenu(!toggleMenu)} className='block md:hidden btn'>
                            <i className='fa fa-bars text-white'></i></button>
                    </div>
                    {
                        toggleMenu && <nav className="headernav">
                            <ul className="flex  gap-10 m-10 absolute">
                                <li><a href="#Products">Products</a></li>
                                <li><a href="#Contact">Contact</a></li>
                            </ul>
                        </nav>
                    }
                    <div className="col-md-6 company">
                        {company.length > 0 && <h4>{company[0].name}</h4> }
                    </div>
                    <div className="col-md-6 navClass">
                        <nav className="headernav">
                            <ul className="flex  gap-10 absolute" style={{"margin":"10px"}}>
                                <li><a href="#Products">Products</a></li>
                                <li><a href="#Contact">Contact</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
        </section>
    );
}