import { useEffect, useState } from "react";
import { getCompany } from "../services/Api";
import './AdminHeader.css';
import { useNavigate } from "react-router-dom";
import { removeToken } from "../services/Storage";
import { Loader } from "rsuite";
export default function AdminHeader() {
    const [company,setCompany] = useState([]);
	const menus = ["Dashboard","Profile","Banner","Logout"];
	const [toggle,setToggle] =useState(false);
	let navgateTo = useNavigate(); 
	const [isLoader,setIsLoader] = useState(false);

    useEffect(()=>{
		getCompany()
		.then( res => { setCompany(res.data.data) })
		.catch((error) => {console.log("Error fetching company : ",error)});
	},[]);

	function handleNavigate(menu) {
		setIsLoader(true);
		var pageTo = menu.toLowerCase();
		if(pageTo === 'logout') {
			removeToken();
			pageTo = 'login';
		}
		navgateTo('/admin/'+pageTo);
		setToggle(false);
		setIsLoader(false);
	}

    return (
        <div>
            <div className='AdminHeader'>
			{isLoader && <Loader size="lg" backdrop content="" />}
                <div className='d-flex p-1'>
                    {company.length > 0 && <>
                        <h4 style={{color:"lightcyan"}}>{company[0].name}</h4>
						<div className="dropdown ms-auto">
							<img alt="logo"
								className="rounded-circle"
								src={`${process.env.REACT_APP_IMG_PATH}${company[0].logo}`}
								style={{"height":"45px","cursor":"pointer"}}
								onClick={(e)=>{setToggle(!toggle)}}
							/>
							{toggle && (<ul className="bg-light position-absolute list-group " style={{"right":"0","width":"300%"}}>
								{
									menus.map((menu)=>{
										return <li 
											key={menu}											
											onClick={()=> {handleNavigate(menu)}}
											className="fs-6 text-decoration-none list-group-item p-2 ps-3 list-group-item-action" 
											style={{"cursor":"pointer"}}>
												{menu==='Dashboard' ? 'Home' : menu}
											</li>
									})
								}
							</ul>)}
						</div>
                    </>}
					
                </div>
            </div>
        </div>
    );
}