import axios from 'axios';

export const getProducts = () => {
    return axios.get(process.env.REACT_APP_API_URL+'/products');   
}

export const getSingleProduct = (data) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/product/${data}`);
}
export const getCompany = () =>{
    return axios.get(process.env.REACT_APP_API_URL+'/company');
}

export const getOrderPDF = (data)=>{
    return axios.get(`${process.env.REACT_APP_API_URL}/order/${data}`);
}

export const getBanner = ()=> {
    return axios.get(`${process.env.REACT_APP_API_URL}/company/banner`)
}

export const getLoginCrendentials = (inputs) => {
    const params = inputs;
    params.password = encodeData(params.password);
    return axios.post(process.env.REACT_APP_API_URL+'/login',{
        params : params
    })


}

export const encodeData = (data) => {
    var stringtoEncode = data;
    var encodedData = btoa(stringtoEncode);
    return encodedData;
}

export const decodeData = (data) => {
    var originalString = atob(data);
    return originalString;
}

export const saveCompany = (data) => {
    return axios.post(process.env.REACT_APP_API_URL+'/company',data,{
        headers: {
          "Content-Type": "multipart/form-data"
        }
    });
}

export const saveCompanyBanner = (data) => {
    return axios.post(process.env.REACT_APP_API_URL+'/company/banner',data,{
        headers: {
          "Content-Type": "multipart/form-data"
        }
    });
}



export const addProducts = (data) => {
    return axios.post(process.env.REACT_APP_API_URL+'/add-products',data,{
        headers: {
          "Content-Type": "multipart/form-data"
        }
    });
}

export const deleteProduct = (data) => {
    return axios.post(process.env.REACT_APP_API_URL+'/delete-product/'+data,);
}