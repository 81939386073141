import { useEffect, useState } from "react";

export default function ImageSlider (props) {
    const {images} = props;
    var length = images.length > 0 ? (images.length - 1) : 2;
    const [currentSlide,setCurrentSlide] = useState(0);
    const [nextSlide,setNextSlide] = useState(1); 
    const [prevSlide,setPrevSlide] = useState(length);
	const [trigger,setTrigger] = useState(true);
	const [isStarted,setIsStarted] = useState(true);
	const [autoPlay,setAutoPlay] = useState(true);
    useEffect(()=>{
        if(images.length > 0 && autoPlay === true) {
                setCurrent();
				setIsStarted(false);
				triggerSlide(); // for autoplay
            //},3000)

        }

    },[autoPlay,trigger])

	const triggerSlide = (val) =>{
		if(!val && val === undefined) {
			setTimeout(()=>{
				setTrigger(!trigger);
			},5000)
		}
	}

	function autoPlaySlides () {
		clearTimeout();
		setAutoPlay(true);
	}

	function stopAutoPlay () {
		setAutoPlay(false);
	}

	function setCurrent (index) {
		if(!index && index === undefined ){
			if(currentSlide === images.length-1 || isStarted){
				setCurrentSlide(0)
			}
			else
				setCurrentSlide(currentSlide+1);
			setSlideNext();
			setSlidePrev();
		}
		else{
			if(index < 0)
				index = images.length-1;
			if(index > images.length-1)
				index = 0;
			var next = index >= (images.length-1) ? 0 : index+1;
			var prev = index === 0 ? (images.length-1) : index-1;
			setCurrentSlide(index);
			setSlideNext(next);
			setSlidePrev(prev);
		}
	}

	function setSlideNext (index) {
		if(!index && index === undefined ){
			setNextSlide((ns)=>{
				if(isStarted)
					return ns;
				if(ns === images.length-1)
					return 0;
				else
					return ns+1;
			})
		} 
		else
			return setNextSlide(index)
	}

	function setSlidePrev (index) {
		if(!index && index === undefined ){
			setPrevSlide((ps)=>{
				if(isStarted)
					return ps;
				else if (ps === images.length-1)
					return 0;
				else
					return ps+1;
			})
		}
		else
			return setPrevSlide(index)
	}

    return (
        <div 
		onMouseEnter={()=>{ return stopAutoPlay(); }}
		onMouseLeave={()=>{ return autoPlaySlides(); }}
		>
        <div id="img_container" className="d-flex" style={{position:"relative",overflow:"hidden",objectFit: "cover"}}>
            {images.map((img,index)=>{
				var translate="";
				if(index === currentSlide)
					translate = `translateX(-${(index) * 100}%)` 
				else if (index < currentSlide)
					translate = `translateX(-${(index + 1) *100}%)`
				else if (index > currentSlide)
					translate = `translateX(-${(index-1) *100}%)`
                return (
					<div 
						key={index+"_link_div"}
						id={index}
						className={currentSlide}
						style={{
							transform : translate,
							transition: "transform 1s ease-in-out",
						}}
					>
                        <img 
                            id={index}
                            key={index+"_link"}
                            src={`${process.env.REACT_APP_IMG_PATH}${img}`}
                            style={{
                                width:'100dvw',
                                height:'50dvh',
                                position:'relative',
								objectFit:"fill"
								//border:"5px solid green"
                            }}
                        />
					</div>
                )
            })}
            <div className="d-flex  align-items-center gap-2" style={{ 
                        marginTop:"70dvh",
                        position:"absolute",
						left:"45%",
						//background:"green",
                    }}>
                {images.map((i,index)=>{
                    return (
                            <a  key={index} onClick={()=>{setCurrent(index)}} 
                                style={{
                                    
                                    background:index === currentSlide ? 'black' : "white",
                                    border:"2px solid white",
                                    borderRadius:"50%",
                                    width:"10px",
                                    height:"10px",
									cursor:"pointer"
                            }}>
                            </a>
                    );
                })}
            </div>
			<a style={{position:"absolute", left:"0",marginTop:"10%",cursor:"pointer"}} onClick={()=>{setCurrent(currentSlide-1)}}><i className="fa fa-caret-left" style={{"fontSize":"100px","color":"white"}}></i></a>
			<a style={{position:"absolute", right:"0",marginTop:"10%",cursor:"pointer"}} onClick={()=>{setCurrent(currentSlide+1)}}><i className="fa fa-caret-right" style={{"fontSize":"100px","color":"white"}}></i></a>
			
        </div>
        </div>
    );

}